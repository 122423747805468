import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Body } from "../../components/Body";
import { Footer } from "../../components/Footer";
import { Gallery } from "../../components/Gallery";
import { Header } from "../../components/Header";

const DessertsPage = () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "desserts" } }
        sort: { fields: [name] }
      ) {
        nodes {
          id
          name
          image: childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Header />
      <Body>
        <h2>Desserts</h2>
        <Gallery images={images} />
      </Body>
      <Footer />
    </>
  );
};

export default DessertsPage;
